<template>
  <div class="rules" style="display: flex;justify-content: center;width: 100%;text-align: left;">
    <div style="width: 95%">
      <h1>交易安全规则</h1>
      <p class="sub">规则发布时间：2022年01月18日</p>
      <hr>
      <p class="up">一、线上交易违规行为规则</p>
      <p>1、 确保订单后未发货，订单发货后，卖方按合同要求履行订单，或在约定的期限内向买家提交订单。</p>
      <p>2、交付货物的实际种类不符合交易合同约定（货物成分/内容////货物等标准）与约定的不符、货物的货物瑕疵或货物的主要质量缺陷、货物功能主要类型或无法正常存在的质量缺陷。使用、质量掉档、短装等）。</p>
      <p>3、不履行承诺。卖方不履行包括但不履行承诺的：</p>
      <p>a) 卖方未按照承诺承诺折扣、赠品、服务等交易标的的利益之外；</p>
      <p>b) 卖方未按照承诺向需求提供退换货、配件、维修、质保等售后服务；</p>
      <p>c) 卖方未按照营销规则或服务协议约定的履约服务承诺；</p>
      <p>d) 卖方承诺其他承诺（有规定）。</p>
      <br>
      <p class="up">二、线下交易欺诈行为规则</p>
      <p>1、卖方存在收款不欺诈行为。</p>
      <p>2、消费者存在收货不付款等欺诈行为。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransactionRule'
}
</script>

<style scoped>

</style>
